import { applyMiddleware, compose, createStore } from "redux";
import thunk from 'redux-thunk';
import reducers from "./Reducers";
const history = require("history").createBrowserHistory()
const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore(initialState) {
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    if (module.hot) {
        module.hot.accept(reducers, () => {
            const nextRootReducer = require(reducers);
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}
export { history };