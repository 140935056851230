export const _messages = {
    /** Util Begin */
    st: "",
    nd: "",
    rd: "",
    th: "",
    Sun: "रवि",
    Mon: "सोम",
    Tue: "मंगल",
    Wed: "बुध",
    Thu: "गुरु",
    Fri: "शुक्र",
    Sat: "शनि",
    Sunday: "रविवार",
    Monday: "सोमवार",
    Tuesday: "मंगलवार",
    Wednesday: "बुधवार",
    Thursday: "गुरुवार",
    Friday: "शुक्रवार",
    Saturday: "शनिवार",
    Jan: "जनवरी",
    Feb: "फरवरी",
    Mar: "मार्च",
    Apr: "अप्रैल",
    Jun: "जून",
    Jul: "जुलाई",
    Aug: "अगस्त",
    Sep: "सितंबर",
    Oct: "अक्टूबर",
    Nov: "नवंबर",
    Dec: "दिसंबर",
    January: "जनवरी",
    Febuary: "फरवरी",
    March: "मार्च",
    April: "अप्रैल",
    May: "मई",
    June: "जून",
    July: "जुलाई",
    August: "अगस्त",
    September: "सितंबर",
    October: "अक्टूबर",
    November: "नवंबर",
    December: "दिसंबर",
    /** Util End 404 Begin*/
    NETWORK_ERROR: "नेटवर्क त्रुटि",
    API_NOT_FOUND: 'ए पी आई नहीं मिला।',
    NOT_FOUND_SHORT_MESSAGE: "उह ओह! आप खो चुके हैं।",
    NOT_FOUND_MESSAGE: "भटकने वाले सभी लोग खो नहीं जाते हैं, होमपेज पर वापस जाने के लिए नीचे दिए गए बटन पर क्लिक करें।",
    HOME: "मुझे घर ले चलो",
    /** 404 End Login Begin */
    USER_NAME: "उपयोगकर्ता नाम",
    PASSWORD: "पासवर्ड",
    LOGIN_MESSAGE: "जारी रखने के लिए लॉगिन करें...",
    LOGIN: "लॉग इन",
    LOGIN_DETAILS_REQUIRED: "उपयोगकर्ता नाम और पासवर्ड की आवश्यकता है।",
    /** Login End Layout Begin */
    COPYWRITE: "कॉपीराइट © 20-22.",
    LOGOUT: "लॉग आउट",
    CONFIRM_TITLE: "क्या आपको यकीन है ?",
    YES: 'हाँ',
    NO: 'नहीं',
    /** Layout End Menu Begin */
    DASHBOARD: "डैशबोर्ड",
    CUSTOMERS: "ग्राहकों",
    PACKAGES: "उत्पाद योजनाएं",
    MODULES: "मॉड्यूल",
    PERIOD: "अवधि",
    SUBSCRIPTIONS: "सदस्यता",
    USER_ACCESS: "मेनू और उपयोगकर्ता पहुंच",
    INVOICES: "चालान",
    TRANSACTIONS: "लेनदेन",
    TYPE: "प्रकार",
    EMAIL: "ईमेल",
    MOBILE: 'मोबाइल',
    BUSSINESS: "व्यापार",
    REPORTS: 'प्रतिवेदनाएं',
    BENEFITS: 'लाभ',
    PAYOUT: 'भुगतान',
    PAYMENT: 'भुगतान',
    FREQUENCY: 'आवृत्ति',
    DAYS: 'दिन',
    FREE: 'मुक्त',
    MONTH: 'महीना',
    MONTHS: 'महीने',
    TRIAL: 'परीक्षण',
    /** Menu End Dashboard Begin */
    SNO: 'क्रमांक',
    ID: 'आईडी',
    LEAD: 'Lead',
    CLIENT_NAME_ID: 'ग्राहक का नाम',
    SPOC: 'स्पोक',
    DUE_DATE: 'देय तिथि',
    PLAN: 'योजना',
    INR: '₹',
    INTERNATIONAL: 'अंतरराष्ट्रीय',
    COUNT: 'संख्या',
    ACTION: 'क्रिया',
    STATUS: 'स्थिति',
    new: 'नए ग्राहक',
    about_to_expire: 'समाप्त होने वाले ग्राहक',
    convertable: 'परिवर्तनीय ग्राहक',
    paid: 'भुगतान करने वाले ग्राहक',
    on_extension: 'विस्तारित ग्राहक',
    trial: 'परीक्षण ग्राहक',
    lost: 'खोया हुआ ग्राहक',
    unconverted: 'अपरिवर्तित ग्राहक',
    blocked: 'अवरुद्ध ग्राहक',
    inactive: 'निष्क्रिय ग्राहक',
    /** Dashboard End */
    CODE: 'कोड',
    NAME: 'नाम',
    PRICE: 'कीमत',
    PRODUCT: 'उत्पाद',
    PACKAGE: 'पैकेज',
    DESCRIPTION: 'विवरण',
    PLANS: 'योजनाएं',
    NEW: 'नई',
    CREATE: 'नई ? बनाएं।',
    MODIFY: '? संशोधित करें।',
    SET_MENUS: 'मेनू सेट करें।',
    SAVE: 'सहेजें',
    CANCEL: 'रद्द करें',
    DOWNLOAD: 'डाउनलोड करें',
    SUBSCRIPTION: 'सदस्यता',
    CUSTOMER: "ग्राहक",
    DISCOUNT: 'छूट',
    CYCLE: 'चक्र',
    BASE: 'आधार',
    VIEW: 'देखे',
    USER: 'उपयोगकर्ता',
    USERS: 'उपयोगकर्ता',
    START_DATE: 'आरंभ तिथि',
    END_DATE: 'समाप्ति तिथि',
    TERMINATION_DATE: 'समापन तिथि',
    INCORPORATION_DATE: 'समावेश तिथि',
    BLOCK: '? अवरुद्ध करें।',
    BLOCK_ALERT: 'सभी सक्रिय सत्रों को समाप्त कर दिया जाएगा और ग्राहक को अवरुद्ध कर दिया जाएगा।\n ग्राहक का नाम: ?\n ग्राहक कोड: ?\n उपयोगकर्ता संख्या: ?',
    VER: 'संस्o.',
    SalaryAdvanceConvFee: 'वेतन अग्रिम सुविधा शुल्क सेट करें',
    BASEDON: 'पर आधारित',
    ADD_AMOUNT: "राशि जोड़ें",
    TRANSACTION_HISTORY: "लेन-देन इतिहास",
    DATE: "तिथि",
    INVOICE: "चालान",
    CUSTOMER_NAME: "ग्राहक का नाम",
    AMOUNT: "राशि",
    DUE_BALANCE: "बकाया राशि",
    SAVE_AS_DRAFT:"ड्राफ्ट के रूप में सेव करें",
    SAVE_AND_SEND:"सहेजें और भेजें",
    SEND: "भेजें",
    PREVIEW: "पूर्व दर्शन"
}