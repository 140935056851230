import { createTheme } from "@mui/material/styles";
const AccountTheme = createTheme({
  palette: {
    primary: {
      main: '#172B4D',
      contrastText: '#ffffff',
      dark: '#135ba1',
      menu: "#ffffff99",
      other: "white",

    },
    secondary: {
      main: '#f50057',
      contrastText: '#ffffff',
      dark: '#c51162',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize"
        },
        contained: {
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#172B4D',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#172b4dd6',
            },
            '&:disabled': {
              backgroundColor: '#9a979787',
            }
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: '#f50057',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#c51162',
            }
          },
        },
        outlined: {
          '&.MuiButton-outlinedPrimary': {
            borderColor: '#172B4D',
            color: '#172B4D',
            '&:hover': {
              borderColor: '#172b4dd6',
              color: '#172b4dd6',
            },
            '&:disabled': {
              color: "gray",
              borderColor: 'gray',
            }
          },
          '&.MuiButton-outlinedSecondary': {
            borderColor: '#f50057',
            color: '#f50057',
            '&:hover': {
              backgroundColor: '#f50057',
              color: '#ffffff',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 4px 0px #c5bdbdb3"
        }
      }
    }
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h3: {
      fontSize: "0.875rem",
    },
    h2: {
      fontSize: "1.25rem",
    },
  },
});
export default AccountTheme;
