import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import AppTheme from './Components/AppTheme';
import App from './App';

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <App />
      {/* <EmailForm/> */}
    </ThemeProvider>
  , document.getElementById('root'));
};

render(App);


if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}