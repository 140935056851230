import axios from "axios";
import swal from 'sweetalert';

import {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_SUCCESS_MSG,
    FETCH_ERROR,
    SHOW_LOADER,
    CLOSE_KYC
} from "../ActionTypes";

import display from "../../lang";

export const setToken = (token) => {
    return localStorage.setItem(`${process.env.REACT_APP_NAME}_token`, token);
}

export const getToken = () => {
    return localStorage.getItem(`${process.env.REACT_APP_NAME}_token`) || false;
}

export const setUser = (user) => {
    return localStorage.setItem(`${process.env.REACT_APP_NAME}_auth`, JSON.stringify(user));
}

export const getUser = (name) => {
    const auth = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth`) || '{}');
    if (name && Object.hasOwnProperty.call(auth, name)) {
        return auth[name];
    } else {
        return auth;
    }
}

export const getHeaders = (customHeader = {}) => {
    let headers = {
        "app_name": `${process.env.REACT_APP_NAME}`,
        "app_lang": getLanguage() || "en",
        "Content-Type": "application/json"
    }
    let token = getToken();
    if (token) {
        headers['token'] = token;
    }
    for (const name in customHeader) {
        if (customHeader[name]) {
            headers[name] = customHeader[name];
        }
    }
    return headers;
}

export const get = (dispatch, url, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/${url}`, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message })
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const post = (dispatch, url, payload, headers, success, error = () => { }, fromHrms = false) => {
    dispatch({ type: FETCH_START });
    axios.post(`${fromHrms ? process.env.REACT_APP_API_BASE_URL_ZIMYO_WORK : process.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error(e);
        });
    });
};

export const patch = (dispatch, url, payload, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.patch(`${process.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error(e);
        });
    });
};

export const downloadFile = (dispatch, url, payload, headers = {}, success = () => { }, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            let { buffer64, file, contentType } = data.data;
            let a = document.createElement("a");
            a.href = `data:${contentType};base64, ${buffer64}`;
            a.setAttribute("download", file);
            a.click();
            dispatch({ type: FETCH_SUCCESS });
            success();
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const downloadFileFromBuffer = (dispatch, payload) => {
    dispatch({ type: FETCH_START });
    let { buffer64, file, contentType } = payload;
    if (buffer64 && contentType) {
        let a = document.createElement("a");
        a.href = `data:${contentType};base64, ${buffer64}`;
        a.setAttribute("download", file);
        a.click();
        dispatch({ type: FETCH_SUCCESS });
    }
    else {
        dispatchError({ message: 'File Not Avaliable' }, dispatch, () => { });
    }
};

export const downloadXls = (dispatch, url, payload, headers = {}, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            let a = document.createElement("a");
            a.href = `data:application/csv;base64, ${data.data}`;
            a.setAttribute("download", `file_${(new Date()) / 1}.csv`);
            a.click();
            dispatch({ type: FETCH_SUCCESS });
            success();
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const checkError = (e, dispatch, next) => {
    if (e.response?.status === 404 || e.response?.status === "404") {
        dispatch({ type: FETCH_ERROR, payload: display('API_NOT_FOUND') });
        next();
    } else {
        if (e.response?.data) {
            dispatchError(e.response.data, dispatch, () => {
                next();
            });
        } else if (e.message === 'Network Error') {
            dispatch({ type: FETCH_ERROR, payload: display('NETWORK_ERROR') });
            next();
        } else {
            dispatch({ type: FETCH_ERROR, payload: e.message });
            next();
        }
    }
};

export const dispatchError = (data, dispatch, next) => {
    if (data?.forceLogin) {
        clearStorage();
        dispatch({ type: FETCH_ERROR, payload: data.message });
    } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: CLOSE_KYC })
        next();
    }
}

export const clearStorage = (reload = true) => {
    reload && reset_url();
    const tabsData = localStorage.getItem('tabs');
    const queryEditorView = localStorage.getItem('queryEditorView');
    localStorage.clear();
    localStorage.setItem('tabs', tabsData);
    localStorage.setItem('queryEditorView', queryEditorView);
    return;
}

export const displayLoader = (dispatch) => {
    dispatch({ type: SHOW_LOADER });
}

export const reset_url = () => {
    setTimeout(() => {
        window.top.location.href = window.top.location.origin;
    }, 1000);
}

export const setLanguage = (lang) => {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = "app_language=" + lang + ";" + expires + ";path=/";
    window.top.location.reload();
}

export const getLanguage = () => {
    let name = "app_language=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const confirmBox = (message, actionFun = (status) => { }) => {
    swal({
        title: display('CONFIRM_TITLE'),
        text: message,
        icon: "warning",
        buttons: [display('NO'), display('YES')],
        dangerMode: true
    }).then(resp => {
        if (resp) {
            actionFun(resp);
        }
    }).catch(err => {

    });
}



export const getZimyoWork = (dispatch, url, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.get(`${process.env.REACT_APP_API_BASE_URL_ZIMYO_WORK}/${url}`, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                // dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message })
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const postZimyoWork = (dispatch, url, payload, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${process.env.REACT_APP_API_BASE_URL_ZIMYO_WORK}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                // dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};



export const getPayroll = (dispatch, url, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.get(`${process.env.REACT_APP_API_BASE_URL_PAYROLL}/${url}`, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message })
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const postPayroll = (dispatch, url, payload, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${process.env.REACT_APP_API_BASE_URL_PAYROLL}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};

export const postBenefits = (dispatch, url, payload, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });
    axios.post(`${process.env.REACT_APP_API_BASE_URL_BENEFITS}/${url}`, payload, {
        headers: getHeaders(headers || {}),
    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data.data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
};


export const sendEmails = (dispatch, url, payload, headers, success, error = () => { }) => {
    dispatch({ type: FETCH_START });

    axios.post(url, payload, {
        headers: getHeaders(headers || {}),

    }).then(({ data }) => {
        if (!data.error) {
            if (data.message && data.message.length > 0) {
                dispatch({ type: FETCH_SUCCESS_MSG, payload: data.message });
            }
            dispatch({ type: FETCH_SUCCESS });
            success(data);
        } else {
            dispatchError(data, dispatch, () => {
                error();
            });
        }
    }).catch((e) => {
        checkError(e, dispatch, () => {
            error();
        });
    });
}